import ZxbCard from './ZxbCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import ZxbListUtil,{IZxbListDataObj} from './zxbListUtil';
import language from './zxbLanguage'
export default defineComponent ({
    name: 'ZxbList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IZxbListDataObj=reactive<IZxbListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                title:'中信保账户',
                cardFrom:'zxbList',
                queryParam: {},
                modelComp: ZxbCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/zxb/pageData')
            },
            otherParams:{
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ZxbListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});