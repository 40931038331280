export default {
    zh:{
        zxb:{
            queryParam:{
                code:'中信保代码',
                code_placeholder:'请输入中信保代码',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                bz:'币种',
                bz_placeholder:'请选择币种',
            },
            columns:{
                F_CODE:'中信保代码',
                F_CUSTOMER_ID:'客户',
                F_DAY:'OA天数',
                F_AMOUNT:'使用限额',
                F_USED_AMOUNT:'已用金额',
                F_PFBL:'赔付比例',
                F_BZ:'币种',
            },
            form:{
                code:'中信保代码',
                code_placeholder:'请输入中信保代码',
                code_rule: '请输入中信保代码',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                customerId_rule: '请选择客户',
                amount:'使用限额',
                amount_rule: '请选择使用限额',
                usedAmount:'已用金额',
                usedAmount_placeholder:'请输入已用金额',
                day:'OA天数',
                day_rule: '请选择OA天数',
                pfbl:'赔付比例',
                pfbl_placeholder:'请输入赔付比例',
                pfbl_rule: '请输入赔付比例',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
            },
        }
    },
    en:{
        zxb:{
            queryParam:{
                code:'中信保代码',
                code_placeholder:'请输入中信保代码',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                bz:'币种',
                bz_placeholder:'请选择币种',
            },
            columns:{
                F_CODE:'中信保代码',
                F_CUSTOMER_ID:'客户',
                F_DAY:'OA天数',
                F_AMOUNT:'使用限额',
                F_USED_AMOUNT:'已用金额',
                F_PFBL:'赔付比例',
                F_BZ:'币种',
            },
            form:{
                code:'中信保代码',
                code_placeholder:'请输入中信保代码',
                code_rule: '请输入中信保代码',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                customerId_rule: '请选择客户',
                amount:'使用限额',
                amount_rule: '请选择使用限额',
                usedAmount:'已用金额',
                usedAmount_placeholder:'请输入已用金额',
                day:'OA天数',
                day_rule: '请选择OA天数',
                pfbl:'赔付比例',
                pfbl_placeholder:'请输入赔付比例',
                pfbl_rule: '请输入赔付比例',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
            },
        }
    }
}